export const breakpoints = {
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 0,
};

const getWindowWidth = () => window.innerWidth;

const getBreakpointSize = () => {
  const windowWidth = getWindowWidth();

  return Object.keys( breakpoints ).find(( key ) => windowWidth >= breakpoints[key]);
};

const appliesTo = ( breakpoint ) => breakpoints[breakpoint] <= getWindowWidth();

const lessThan = ( breakpoint ) => getWindowWidth() < breakpoints[breakpoint];

export default {
  getWindowWidth,
  getBreakpointSize,
  appliesTo,
  lessThan,
};
